import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Link from "next/link";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "util/auth.js";
import Button from "react-bootstrap/Button";
import styles from "./NavbarCustom.module.scss";
import { ANCHORS } from "util/Config";

function NavbarCustom(props) {
  const [expanded, setExpanded] = useState(false);
  const auth = useAuth();

  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      bg={props.bg}
      variant={props.variant}
      expand={props.expand}
      expanded={expanded}
      style={{
        boxShadow: "0px 1px 1px #ddd",
      }}
    >
      <Container>
        <Link href="https://trymycar.com" passHref={true}>
          <Navbar.Brand>
            <div
              onClick={() =>
                setTimeout(() => {
                  setExpanded(false);
                }, 150)
              }
            >
              <img
                className="d-inline-block"
                src={props.logo}
                alt="Logo"
                height="29"
              />
            </div>
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle
          onClick={() =>
            setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))
          }
          aria-controls="navbar-nav"
          className="border-0"
        />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            {/* <Link href="/#about" passHref={true} scroll={false}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Apie mus
              </Nav.Link>
            </Link> */}

            {/* <Link href="/#features" passHref={true}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Savybės
              </Nav.Link>
            </Link>

            <Link href="/#team" passHref={true}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Komanda
              </Nav.Link>
            </Link> */}

            {/* <Link href="/career" passHref={true}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Karjera
              </Nav.Link>
            </Link> */}

            {/* <Link href={`/#${ANCHORS.CONTACTS_ANCHOR_LINK}`} passHref={true}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Kontaktai
              </Nav.Link>
            </Link> */}
            <Link href="/faq" passHref={true}>
              <Nav.Link
                className={styles.extraSpace}
                active={false}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Kaip tai veikia?
              </Nav.Link>
            </Link>

            {!auth.user && (
              <Nav.Item>
                <Link href="/auth/signin?next=/dashboard" passHref={true}>
                  <Nav.Link
                    className={styles.extraSpace}
                    active={false}
                    onClick={() =>
                      setTimeout(() => {
                        setExpanded(false);
                      }, 150)
                    }
                  >
                    Prisijunk
                  </Nav.Link>
                </Link>
              </Nav.Item>
            )}
            {/* **** AUTHENTICATED MENU ***** */}
            {auth.user && (
              <NavDropdown
                className={styles.extraSpace}
                id="dropdown"
                title="Jūsų paskyra"
                alignRight={true}
              >
                <Link href="/dashboard/my-cars" passHref={true}>
                  <NavDropdown.Item
                    active={false}
                    onClick={() =>
                      setTimeout(() => {
                        setExpanded(false);
                      }, 150)
                    }
                  >
                    Mano automobiliai
                  </NavDropdown.Item>
                </Link>

                <Dropdown.Divider />

                <Link href="/auth/signout" passHref={true}>
                  <NavDropdown.Item
                    className={styles.extraSpace}
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                      setTimeout(() => {
                        setExpanded(false);
                      }, 150);
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>
            )}
            <Link href="/dashboard/add-car" passHref={true}>
              <Button
                variant="primary"
                style={{ backgroundColor: "#4285F4", borderColor: "#4285F4" }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false);
                  }, 150)
                }
              >
                Įdarbink savo automobilį
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
