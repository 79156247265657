import React from "react";
import Section from "components/Section";
import Container from "react-bootstrap/Container";
import Link from "next/link";
import styles from "./Footer.module.scss";

function Footer(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
    >
      <Container>
        <div className={styles.FooterComponent__inner}>
          <div className={`${styles.brand} ${styles.left}`}>
            <Link href="/">
              <a>
                <img src={props.logo} alt="Logo" />
              </a>
            </Link>
          </div>
          <div className={`${styles.links} ${styles.right}`}>
           <Link href="/tc">
              <a>Paslaugų teikimo taisyklės</a>
            </Link>
            <Link href="/cookie-policy">
              <a>Slapukų politika</a>
            </Link>
            <Link href="/privacy-policy">
              <a>Privatumo politika</a>
            </Link>
          </div>  
            {/* <a
              target="_blank"
              href="https://medium.com"
              rel="noopener noreferrer"
            >
              Blogas
            </a> */}
          <div className={`${styles.social} ${styles.right}`}>
            <a
              href="https://www.linkedin.com/company/trymycar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-linkedin" />
              </span>
            </a>
            <a
              href="https://facebook.com/trymycar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-facebook-f" />
              </span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCfHR_1SmTlRMYsQufcoXreg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-youtube" />
              </span>
            </a>
            <a
              href="https://www.instagram.com/trymycar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-instagram" />
              </span>
            </a>
          </div>
          <div className={`${styles.copyright} ${styles.left}`}>
            <div>
              {props.copyright}
            </div>
            <div>
              <a href="mailto:support@trymycar.com">support@trymycar.com</a>
            </div>
            <div>
              <a style={{ textDecoration: 'none' }} href="tel:+370 625 13762">+370 625 13762</a>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default Footer;
