import * as Config from './Config';
import et from 'date-fns/locale/et';
import lt from 'date-fns/locale/lt';
import en from 'date-fns/locale/en-US';

export const Language = {
   ENG: 0x00,
   EST: 0x01,
   LIT: 0x02,
};

export function getDateFnsLocale(language) {
   return {
      0x00: en,
      0x01: et,
      0x02: lt
   }[language];
}

export function getDateTimePickerLocale(language) {
   return {
      0x00: 'en-US',
      0x01: 'et-EE',
      0x02: 'lt-LT'
   }[language];
}

export const Dictionary = {
   // Generic
   Yes: ["Yes", "Jah", "Taip"],
   No: ["No", "Ei", "Ne"],
   OK: ["OK", "OK", "OK"],
   Cancel: ["Cancel", "Tühista", "Atšaukti"],
   Update: ["Update", "Uuenda", "Atnaujinti"],
   NotNow: ["Not Now", "Hiljem", "Vėliau"],
   Skip: ["Skip", "Jäta vahele", "Praleisti"],
   Next: ["Next", "Edasi", "Toliau"],
   Done: ["Done", "Valmis", "Baigta"],
   Continue: ["Continue", "Edasi", "Tęsti"],
   Notification: ["Notification", "Teavitus", "Pranešimas"],
   ErrorAlertTitle: ["Request Failed", "Tegevus ebaõnnestus", "Užklausa nepavyko"],
   ErrorAlertMessage: ["Please try again.", "Palun proovi uuesti.", "Prašome bandyti dar kartą"],
   Confirm: ['Confirm', 'Kinnita', 'Patvirtinti'],

   // HomeScreen
   homePriceH: [" € per hour", " € tund", " € per valandą"],
   homePriceD: [" € per day", " € päev", " € per dieną"],
   homePriceT: [" € total price", " € total", " € už nuomą"],
   homeCarBooked: ["This car is booked,\nplease try another one!", "See auto on broneeritud,\npalun muutke ajavahemikku!", "Šis automobilis rezervuotas,\nprašome bandykite kitą"],
   homeCarsNA: ["All cars are booked,\nplease change the booking time!", "Kõik autod on broneeritud,\npalun muutke ajavahemikku!", "Atsiprašome visi automobiliai rezervuoti,\nbandykite pakeisti rezervacijos laiką"],


   // ENUMS:
   carTransmission: {
      0x00: ["Manual", "Manuaal", "Mechaninė"],
      0x01: ["Automatic", "Automaat", "Automatinė"],
   },
   carType: {
      0x00: ["Hatchback", "Luukpära", "Hečbekas"],
      0x01: ["Estate", "Universaal", "Universalas"],
      0x02: ["Saloon", "Sedaan", "Sedanas"],
      0x03: ["SUV", "Maastur", "Visureigis (SUV)"],
      0x04: ["Van", "Kaubik", "Mikroautobusas"],
   },
   fuel: {
      0x00: ["95 petrol", "95", "95 benzinas"],
      0x01: ["98 petrol", "98", "98 benzinas"],
      0x02: ["Diesel", "Diisel", "Dyzelis"],
      0x03: ["Electric", "Elekter", "Elektra"],
      0x04: ["Hybrid", "Hybrid", "Hibridas"],
      0x05: ["Liquified gas", "Liquified gas", "Dujos"],
   },
   // CarDetailsScreen
   // BookingScreen
   carDetailsMan: ["Manual", "Manuaal", "Mechaninė"],
   carDetailsAut: ["Automatic", "Automaat", "Automatinė"],
   carDetails95: ["95", "95", "95"],
   carDetails98: ["98", "98", "98"],
   carDetailsDiesel: ["Diesel", "Diisel", "Dyzelis"],
   carDetailsElectric: ["Electric", "Elekter", "Elektra"],
   carDetailsHybrid: ["Hybrid", "Hybrid", "Hybrid"],
   booking: ["Booking", "Broneering", "Rezervacija"],
   bookingTotalPrice: ["Total Price", "Kogu broneeringu maksumus", "Visa kaina"],
   bookingDeposit: ["Deposit", "Deposiit", "Depozitas"],
   bookingLocation: ["Pickup & Return Location", "Sõiduki asukoht", "Paėmimo ir grąžinimo vieta"],
   bookingNotes: ["Notes", "Märkmed", "Pastabos"],
   bookingYear: ["Year", "Aasta", "Metai"],
   bookingMilage: ["Milage", "Läbisõidumõõdiku näit", "Kilometražas"],
   bookingTransmission: ["Transmission", "Käigukast", "Pavara"],
   bookingFuel: ["Fuel", "Kütus", "Kuras"],
   bookingFuelConsumption: ["Fuel Consumption", "Kütusekulu", "Kuro suvartojimas"],
   bookingSeats: ["Seats", "Istekohti", "Sėdimos vietos"],
   bookingTermsTitle: ["Terms & Conditions", "Kasutustingimused", "Paslaugos sąlygos"],
   bookingAcceptTermsConditions: ["I accept the terms and conditions and give permission to process my personal data as provided by me", "Kasutajaks registreerumisega nõustun kasutustingimustega ning annan oma isikuandmete töötlemiseks kasutajatingimuste punktides 2.1.2.c-f nimetatud nõusolekud", "Sutinku, kad mano pateikti privatūs duomenys būtų tvarkomi pagal paslaugos teikimo sąlygas"],
   bookingBookPay: ["Book", "Broneeri","Rezervuoti"],
   bookingAgree: ["Agree", "Nõustun", "Sutinku"],
   bookingDisagree: ["Disagree", "Ei nõustu", "Nesutinku"],
   bookingVerifyEmail: ["Please verify your email by clicking the link at the email we just sent to you.", "Palun kinnita oma email vajutades e-kirjas olevale lingile.", "Prašome patvirtinti el pašto adresą paspaudžiant nuorodą el laiške, kurį ką tik išsiuntėme Jums"],
   bookingVerifying: ["We are verifying your profile. Thank you for your patience!", "Teostame taustakontrolli. Tööpäevadel vahemikus 9.00-20.00 võtab kontrolli teostamine tavaliselt kuni 1 tund, kuid nädalavahetustel võib minna kauem. Täname kannatlikkuse eest!", "Tikriname jūsų duomenis. Jų peržiūrą stengiamės atlikti kuo greičiau. Ačiū už supratingumą!"],
   bookingPleaseWait: ["Please Wait!", "Palun oota!", "Prašome palaukti!"],
   bookingSaving: ["You'll save ", "Säästad ", "Jūs sutaupysite "],
   bookingNotConfirmed: ['Waiting for owner to confirm booking.', '', 'Laukiama automobilio savininko patvirtinimo.'],

   // PaymentScreen
   paymentTitle: ["Payment\n", "Makse\n", "Mokėjimas\n"],
   paymentPay: ["Pay", "Maksa", "Mokėti"],
   paymentDeposit: ["deposit", "deponeeri", "depozitas"],

   // ReqAuthScreen
   ReqAuthPhoneNumber: ["Phone Number", "Telefoninumber", "Telefono numeris"],

   // BaseUserInputScreen
   UserInputEmpty: ["Please enter the data!", "Palun sisestage andmed!", "Prašome įvesti duomenis!"],
   UserReqPhoneNumber: ["My\nphone number is", "Minu\ntelefoninumber on", "Mano\ntelefono numeris yra"],
   UserReqAuthCode: ["My authentication\ncode is", "Minu\nautentimiskood on", "Mano patvirtinimo\nkodas yra"],
   UserReqEmail: ["My\nemail address is", "Minu e-posti\naadress on", "Mano\nelektroninio pašto adresas yra"],
   UserReqAddress: ["My\nhome address is", "Minu kodune\naadress on", "Mano \nadresas yra"],
   UserTermsConditions: ["I accept the terms\nand conditions", "Nõustun\nkasutustingimustega", "Aš sutinku su paslaugos \nnaudojimo sąlygomis"],
   UserTermsConditionsRead: ["Read the terms and conditions", "Tutvu kasutustingimustega","Perskaitykite paslaugos naudojimo sąlygas"],
   UserCameraNotification: ["Your identity must be verified before you can use TryMyCar. Please enable camera access to photograph your driver’s license and your face.", "TrymyCar kasutamiseks veendutakse teie isikusamasuses. Palun lubage kaamera kasutamine ja tehke pildid nii oma juhiloast kui ka näost.", "Jūsų tapatybė turi būti nustatyta prieš pradedant naudotis TryMyCar paslaugomis. Suteikite teisę pasinaudoti kamera ir nufotografuoti Jūsų vairuotojo teises bei Jūsų veidą."],
   UserRegistration: ["Registration", "Registreerimine", "Registracija"],
   UserRegistrationText: ["Register in advance, because the background check could take some time before you can start renting. Registration is free and takes about a minute, however, you will need your driver's licence.", "Registreeri kasutajaks, sest taustakontrolli teostamine võtab aega. Registreerimine on tasuta, võtab ühe minuti ning selleks on vaja juhiluba.", "Užsiregistruokite iš anksto, nes asmens duomenų patikra gali užtrukti. Registracija yra nemokama ir trunka kelias minutes."],

   // UserDataScreen
   UserDataFirstName: ["First Name", "Eesnimi", "Vardas"],
   UserDataLastName: ["Last Name", "Perekonnanimi", "Pavardė"],
   UserDataIdCode: ["ID Code", "Isikukood", "Asmens kodas"],
   UserDataEmail: ["Email Address", "E-post", "El. paštas"],
   UserDataAddress: ["Address", "Postiaadress", "Adresas"],
   UserDataLicNr: ["Driver's License Number", "Juhiloa number", "Vairuotojo teisių numeris"],
   UserDataLicExpDate: ["Driver's License Expiration Date", "Juhiloa kehtivusaeg", "Vairuotojo teisių galiojimo data"],

   // SelfieCameraScreen
   // LicenseCameraScreenf
   selfieCameraL1: ["TAKE A PHOTO\nOF YOUR FACE", "TEE ENDAST PILTI\n(SELFIE)", "NUFOTOGRAFUOKITE\nSAVO VEIDĄ"],
   licenseCameraFrL1: ["PHOTOGRAPH THE\nFRONT OF YOUR\nDRIVER'S LICENSE", "TEE JUHILOA\nESIKÜLJEST PILT", "NUFOTOGRAFUOKITE\nTEISIŲ PRIEKINĘ DALĮ"],
   licenseCameraReL1: ["PHOTOGRAPH THE\nREAR OF YOUR\nDRIVER'S LICENSE", "TEE JUHILOA\nTAGAKÜLJEST PILT", "NUFOTOGRAFUOKITE\nTEISIŲ GALINĘ DALĮ"],

   // UnlockCameraScreen
   // LockCameraScreen
   cameraPhotographL1: ["TAKE A PHOTO\n", "TEE PILT SÕIDUKI\n", "PADARYKITE NUOTRAUKĄ "],
   cameraPhotographFrLeftL2: ["FROM THE FRONT LEFT\nCORNER OF THE CAR", "ESIMESEST VASAKUST NURGAST", "IŠ PRIEKINIO KAIRIO AUTOMOBILIO KAMPO"],
   cameraPhotographReLeftL2: ["FROM THE REAR LEFT\nCORNER OF THE CAR", "TAGUMISEST VASAKUST NURGAST", "IŠ GALINIO KAIRIO AUTOMOBILIO KAMPO"],
   cameraPhotographFrRightL2: ["FROM THE FRONT RIGHT\nCORNER OF THE CAR", "ESIMESEST PAREMAST NURGAST", "IŠ PRIEKINIO DEŠINIO AUTOMOBILIO KAMPO"],
   cameraPhotographReRightL2: ["FROM THE REAR RIGHT\nCORNER OF THE CAR", "TAGUMISEST PAREMAST NURGAST", "IŠ GALINIO DEŠINIO AUTOMOBILIO KAMPO"],
   cameraPhotographInteriorL2: ["OF THE INTERIOR FROM\nTHE DRIVER DOOR", "SISEMUSEST JUHIUKSE VAHELT", "AUTOMOBILIO VIDUJE, PRADARIUS VAIRUOTOJO DURIS"],
   cameraPhotographL3: ["", "", ""],
   cameraPermissionTitle: ["Permission to Use Camera", "Kaamera kasutusõigused", "Leidimas naudoti telefono kamerą"],
   cameraPermissionMessage: ["We need your permission to use the camera!", "Palun lubage rakendusel kaamera kasutamine!", "Reikia jūsų leidimo naudoti kamerą"],
   cameraDoorsAreUnlocked: ["Doors are now open. You can find the key fob in the glove compartment. During the rental session use the key fob to open and close the doors. Have a good ride!", "Uksed on nüüd avatud. Sõiduki puldi leiad kindalaekast. Rendisessiooni vältelt kasuta uste avamiseks ja sulgemiseks pulti. Head sõitmist!", "Durys atrakintos. Raktelius rasite daiktadėžėje. Nuomos metu, naudokite raktelius atrakinimui ir užrakinimui. Geros kelionės!"],
   cameraDoorsWillBeLocked: ["Please take all your personal belongings from the car and make sure that all the doors and windows are closed. After pressing \"OK\" we will lock the doors.", "Palun võta kõik isiklikud asjad sõidukist kaasa ning veendu, et kõik uksed ja aknad oleksid suletud. Pärast \"OK\" vajutamist lukustame sõiduki uksed.", "Prašome pasiimti visus jums priklausančius daiktus iš automobilio, ir patikrinkite, ar visi langai bei durys yra uždaryti. Paspaudus \"OK\", užrakinsime duris."],
   cameraDoorsHaveLockedTitle: ["Are the doors locked?", "Kas uksed on lukustatud?", "Ar durys užsirakino?"],
   cameraDoorsHaveLocked: ["Are the doors locked? If the turn signals have not blinked yet then wait for 30 seconds. NB! Please do not open the doors before the turn signals have blinked or 30 seconds has passed.", "Kas uksed läksid lukku? Kui suunatuled ei ole veel vilkunud, siis oota 30 sekundit. NB! Palun enne suunatulede vilkumist või 30 sekundi möödumist uksi mitte avada.", "Ar durys užsirakino? Jei posūkių lempos nesublyksėjo, tuomet palaukite 30 sekundžių. Svarbu: nebandykite atidaryti durų, kol nesublyksėjo posūkių lempos arba nepraėjo 30 sekundžių."],
   cameraBookingCompletedTitle: ["Rental Session Completed", "Rendisessioon on lõpetatud", "Nuoma pasibaigė."],
   cameraBookingCompletedMessage: ["Thank you for using TryMyCar! We would love to hear your feedback on the email we just sent to you. Until next time!", "Täname, et kasutasite TryMyCar'i! Palume jätta tagasiside emailis, mille Teile just saatsime. Järgmiste sõitudeni!", "Ačiū, kad naudojatės TryMyCar! Norėtume išgirsti jūsų atsiliepimus el. paštu, kurį ką tik jums išsiuntėme. Iki kito karto!"],
   cameraCallInfoTitle: ["Rental Session Completed", "Rendisessioon on lõpetatud", "Nuomos sesija sėkmingai baigta"],
   cameraCallInfoMessage: ["Please call the customer support at +370 625 13762.", "Palun võtke ühenust klienditoega numbril +370 625 13762.", "Prašome skambinti klientų aptarnavimo numeriu +370 625 13762"],
   cameraDoorLockFailedTitle: ["Communication Error", "Ühenduse viga", "Ryšio klaida"],
   cameraDoorLockFailedMessage: ["Failed to control the doors, please try again!", "Uste juhtimine ebaõnnestus, palun proovige uuesti!", "Nepavyko susirišti su durų mechanizmu, bandykite dar kartą"],

   // RentalScreen
   rentalUnlock: ["Begin", "Alusta", "Pradėti"],
   rentalFindCar: ["Find Car", "Leia auto", "Ieškoti automobilio"],
   rentalBookedFrom: ["From ", "Alates ", "Nuo"],
   rentalCarTooFar: ["Car Too Far", "Sõiduk liiga kaugel", "Automobilis per toli"],
   rentalLock: ["End", "Lõpeta", "Užbaigti"],
   rentalFindReturn: ["Find Return", "Leia tagastuspunkt", "Rasti grąžinimo vietą"],
   rentalBookedUntil: ["Until ", "Kuni ", "Iki"],
   rentalReturnTooFar: ["Return Too Far", "Tagastuspunkt liiga kaugel", "Leistina grąžinimo vieta per toli"],
   rentalCarTypeHatchback: ["Hatchback", "Luukpära", "Hečbekas"],
   rentalCarTypeEstate: ["Estate", "Universaal", "Universalas"],
   rentalCarTypeSaloon: ["Saloon", "Sedaan", "Sedanas"],
   rentalCarTypeSUV: ["SUV", "Maastur", "Visureigis (SUV)"],
   rentalCarTypeVan: ["Van", "Kaubik", "Mikroautobusas"],

   // AboutScreen
   aboutMessengerSub: ["Facebook Messenger", "Facebook Messenger", "Facebook Messenger"],
   aboutMailSub: ["Email", "E-post", "El. paštas"],
   aboutPhoneSub: ["Contact", "Kontakttelefon", "Kontaktinis telefonas"],
   aboutPlatformProviderSub: ["Platform Provider", "Maaklerteenuse pakkuja", "Platformos tiekėjas"],
   aboutRegNrSub: ["Reg. Nr.", "Reg. nr.", "Reg. nr."],
   aboutAddressSub: ["Address", "Aadress", "Adresas"],
   aboutVatNrSub: ["VAT Nr.", "KMKR", "PVM kodas"],
   aboutIbanSub: ["IBAN", "IBAN", "IBAN"],
   aboutConditionsSub: ["Service Usage Conditions", "Teenuse kasutamise tingimused", "Paslaugos vartojimo sąlygos"],
   aboutPrivacySub: ["Privacy Policy", "Privaatsuspoliitika", "Privatumo politika"],
   aboutIntro: ["TryMyCar is a P2P car rental platform that makes it possible to quickly and comfortably rent the car that suits you best.\n\nWish to add your car to TryMyCar? Learn more via TryMyCar.com/#register", "TryMyCar on autorendi platvorm, kust saad mugavalt ja kiirelt rentida just Sulle sobiva sõiduki.\n\nSoovid oma sõidukit TryMyCar'i platvormile lisada? Vaata lähemalt TryMyCar.com/#register", "TryMyCar yra tarpusavio (P2P) automobilių nuomos platforma, kurioje galite greitai ir lengvai išsinuomoti jums tinkamą automobilį.\n\nNorite pridėti savo automobilį į TryMyCar? Sužinokite daugiau apsilankę TryMyCar.com/#register"],
   aboutInformation: ["About Us", "Ettevõttest", "Apie mus"],
   aboutTutorial: ["View the Tutorial", "Vaata kasutusjuhiseid", "Peržiūrėti įvadinę instrukciją"],
   aboutTutorialSub: ["How to Use TryMyCar?", "Kuidas kasutada TryMyCar'i?", "Kaip naudotis TryMyCar?"],
   aboutContacts: ["Contacts", "Kontaktid", "Kontaktai"],
   aboutFAQ: ["FAQ", "KKK", "D.U.K."],

   // SettingsScreen
   settingsLanguageEng: ["English", "Inglise keel (English)", "Anglų kalba (English)"],
   settingsLanguageEst: ["Estonian", "Eesti keel", "Estų kalba (Estonian)"],
   settingsLanguageLit: ["Lithuanian", "Leedu keel (Lithuanian)", "Lietuvių kalba"],
   settingsLanguage: ["Language", "Keel", "Kalba"],
   settingsEnvironment: ["Environment", "Keskkond", "Aplinka"],
   settingsVehicle: ["Vehicles", "Sõidukid", "Automobiliai"],
   settingsVehicleEntry: ["Vehicle Details", "Sõiduki andmed", "Automobilio savybės"],
   settingsUser: ["User", "Kasutaja", "Vartotojas"],
   settingsEmptyUserDetails: ["Book a car in order to register or to log in!", "Broneeri sõiduk, et registreerida või sisse logida!", "Pasirinkite rezervuoti automobilį, kad galėtumėte prisijungti prie sistemos"],
   settingsLogInRegister: ["Log In or Register", "Logi sisse või registreeri", "Prisijunkite arba užsiregistruokite"],
   settingsLogOutUser: ["Log Out", "Logi välja", "Atsijungti"],
   settingsDeleteUser: ["Delete User & Data", "Kustuta kasutaja ja andmed", "Ištrinti savo paskyrą"],
   settingsDeleteUserPopUp: ["Deleting User & Data", "Kasutaja ja andmed kustutatakse", "Trinama vartotojo paskyra"],
   settingsDeleteUserConfirmation: ["Please confirm, this cannot be undone.", "Palun kinnita, andmeid ei saa taastada.", "Patvirtinkite šį veiksmą. Veiksmas bus nebeatšaukiamas."],
   settingsDelete: ["Delete", "Kustuta", "Ištrinti"],

   // VehicleManagementScreen
   vehicleManagementDoorTitle: ["Door Lock", "Ukselukk", "Durų užrakinimas"],
   vehicleManagementDoorsUnlock: ["Unlock the doors", "Ava uksed", "Atrakinti duris"],
   vehicleManagementDoorsLock: ["Lock the doors", "Sulge uksed", "Užrakinti duris"],

   // TutorialScreen
   tutorialSlide1Title: ["Book in advance", "Broneeri varakult", "Rezervuoti iš anksto"],
   tutorialSlide1Text: ["Select the start and end time of the rental period and book in advance.", "Vali rendiperioodi algus- ja lõppaeg ning broneeri auto ette.", "Pasirinkite nuomos pradžios ir pabaigos laiką ir rezervuokite iš anksto."],
   tutorialSlide2Title: ["Variety of cars", "Lai valik autosid", "Įvairūs automobiliai"],
   tutorialSlide2Text: ["Everything from small city cars to minibuses and vans - we\'ve got you covered!", "Saadaval on erinevaid sõidukeid väikeautodest kaubikute ja väikebussideni välja. ", "Viskas nuo mažų miesto automobilių iki miniautobusų ir krovininių automobilių - ko tik Jums tuo metu reikia!"],
   tutorialSlide3Title: ["Seamless payment", "Mugav makselahendus", "Lengvas apmokėjimas"],
   tutorialSlide3Text: ["Pay with a credit or debit card. We will reserve a " + Config.DEFAULT_DEPOSIT + " € deposit, which is released within 7 days after the rental.", "Maksa krediit- või deebetkaardiga. Me reserveerime deposiidi (" + Config.DEFAULT_DEPOSIT + " €), mis vabastatakse 7 päeva jooksul pärast rendiperioodi lõppu.", "Mokėkite banko kortele. Mes rezervuosime " + Config.DEFAULT_DEPOSIT + " € depozitą, kurį grąžinsime jums po 7 dienų nuo nuomos pabaigos."],
   tutorialSlide4Title: ["Keyless entry", "Võtmevaba uste avamine", "Atrakinimas mobilia programėle"],
   tutorialSlide4Text: ["Walk to the car and open the doors from the app. Use the key during the session - you will find it from the glove compartment.", "Jaluta sõidukini ning ava uksed nutirakendusest. Rendiperioodi jooksul kasuta kindalaekas olevat võtit. ", "Priekite prie automobilio ir atrakinkite dureles tiesiai iš mobilios programėlės. Raktelius naudokite nuomos metu - juos rasite daiktadėžėje."],
   tutorialSlide5Title: ["Pickup & return", "Sõiduki tagastamine", "Pasiėmimas ir grąžinimas"],
   tutorialSlide5Text: ["Refuel and wash the car, then return it to the pickup location. Drop us a message, if you wish to extend your booking.", "Tagasta tangitud ning puhas sõiduk samasse kohta, kus see varem paiknes. Kirjuta meile, kui soovid broneeringut pikendada. ", "Pripildykite automobilį degalų, ir grąžinkite jį į pasiėmimo vietą. Parašykite mums žinutę, jei norite pratęsti nuomą."],
   tutorialSlide6Title: ["Looks cool? Sign up!", "Kõlab hästi? Registreeri!", "Viskas tinka? Registruokitės!"],
   tutorialSlide6Text: ["Sign up in advance, as your identity has to be verified. Keep your driver\'s license close for that. No more paperwork or standing in line.", "Registreeri varakult, kuna kasutaja verifitseerimine võib aega võtta. Registreerimiseks on vaja juhiluba. Rendi ilma järjekordadeta!", "Užsiregistruokite iš anksto, nes jūsų tapatybė turės būti patvirtinta mūsų darbuotojų. Šalia turėkite savo vairavimo teises. Daugiau jokių popierių ir jokio laukimo eilėse."],

   // UpdatePopupScreen
   updatePopupTitle: ["Update TryMyCar", "Uuenda TryMyCar'i rakendust", "Atnaujinti TryMyCar"],
   updatePopupText: ["We are constantly updating TryMyCar in order to improve your rental experience. Get the latest features by keeping the app up to date.", "Me täiendame pidevalt TryMyCar'i rakendust pakkumaks paremat renditeenust. Täiendatud funktsionaalsuse kasutamiseks uuendage rakendust. ", "Mes nuolatos atnaujiname TryMyCar, kad nuomą padarytume dar patogesne. Atnaujinkite programėlę, kad visada turėtumėte naujausias jos funkcijas."],

   // Alerts
   alertLocationServices: ["We need your location to show you the nearest cars and provide you with a smooth service. TryMyCar will not work when location services are disabled. Please enable the location services.", "TryMyCar ei tööta kui asukohateenused ei ole lubatud. Palun lubage asukohateenused.", "Mums reikia Jūsų lokacijos, kad galėtume jums rodyti artimiausius automobilius ir suteiktume jums pilną paslaugą. TryMyCar neveiks be leidimo naudoti telefono lokaciją. Prašome leisti naudoti telefono lokaciją."],
   alertConnectionError: ["Connection Error", "Ühenduse viga", "Ryšio klaida"],
   alertConnectionErrorSub: ["Unable to connect to remote server, please check your network connection.", "Ühendus serveriga puudub, palun kontrollige võrguühendust.", "Negalime pasiekti serverio, pasitikrinkite, ar veikia jūsų interneto ryšys."],
   alertError: ["Error", "Viga", "Klaida"],
   alertBookingError: ["Unable to Create Booking!", "Broneeringu tegemine ebaõnnestus!", "Negalime sukurti rezervacijos!"],
   alertUnlockError: ["Unable to Unlock Doors!", "Uste avamine ebaõnnestus!", "Nepavyko atrakinti durų!"],
   alertBookingEnded: ["The booking has already ended, it is not possible to unlock the doors.", "", "Nuomos laikas pasibaigė, nebegalima atrakinti durų."],
   alertBookingNotStarted: ["The booking has not started yet.", "Broneering ei ole veel alanud.", "Nuomos laikas dar neprasidėjo."],
   alertInvalidCode: ["Invalid Code", "Vale kood", "Neteisingas kodas"],
   alertPhoneNumber: ["Phone number must not include characters!", "Telefoninumber ei tohi sisaldada tähti!", "Telefono numeryje negali būti specialiųjų simbolių (tik skaičiai ir \"+\")!"],
   alertAuthCode1: ["Authentication code must have ", "Autentimiskood peab sisaldama ", "Patvirtinimo kodas turi turėti "],
   alertAuthCode2: [" digits!", " numbrit!", " skaitmenis!"],
   alertAuthCode3: ["Authentication code must only include numbers!", "Autentimiskood peab sisaldama ainult numbreid!", "Patvirtinimo kodas gali turėti tik skaičius"],
   alertVerifyCode: ["Please verify the code!", "Palun kontrollige koodi!", "Prašome pasitikrinti kodą!"],
   alertEmptyFields: ["Empty Input Fields", "Tühjad väljad", "Tušti laukeliai"],
   alertFillFields: ["Please fill all input fields!", "Palun täitke kõik väljad!", "Prašome užpildyti visus laukelius"],
   alertInvalidName: ["Invalid Name", "Vigane nimi", "Netinkamas vardas"],
   alertNameError: ["Name must only include characters!", "Nimi peab sisaldama ainult tähti!", "Vardas gali susidaryti tik iš raidžių!"],
   alertInvalidID: ["Invalid ID Code", "Vigane ID kood", "Netinkamas asmens kodas"],
   alertNumberError: ["Input must only include numbers!", "Sisend peab sisaldama ainult numbreid!", "Laukelyje galimi tik skaičiai"],
   alertDoors1: ["The doors can be locked and unlocked only within ", "Sõiduki uksed on võimalik avada ainult ", "Dureles galite atrakinti būdami ne toliau kaip "],
   alertDoors2: [" meters from the pickup or return location.", " meetri raadiuses rentimis- või tagastamisasukohast.", " metrų nuo automobilio pasiėmimo lokacijos."],
   alertDoorsForOwner2: [" meters from the vehicle location.", " meetri raadiuses sõiduki asukohast.", " metrų nuo automobilio."],
   alertBookingCancelled: {
      title: ["The booking was cancelled", "", "Rezervacija atšaukta"],
      content: ['The booking was cancelled by the car owner or the booking expired. Please book another car.', '', 'Rezervacija buvo atšaukta automobilio savininko arba rezervacijos laikas pasibaigė.'],
   },

   // DateTimePicker
   pickADate: ['Pick a date', 'Vali kuupäev', 'Pasirinkite datą'],
   Lithuania: ['Lithuania', 'Leedu', 'Lietuva'],
};

export function getTranslatedAddressLine(addressLine, lang) {
   const [city, country] = addressLine.split(',').map(l => l.trim());
   return city && country ?
      `${city}, ${Dictionary[country][lang]}` :
      addressLine;
}
