import React, { useEffect } from "react";
import "styles/global.scss";
// import "styles/froala_blocks.scss";
import NavbarCustom from "components/NavbarCustom";
import Footer from "components/Footer";
import "util/analytics.js";
import { AuthProvider } from "util/auth.js";
import * as ga from 'util/ga'
import { useRouter } from 'next/router';
import FacebookPixel from 'components/FacebookPixel';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import * as mp from 'util/mixpanel';
import mixpanel from 'mixpanel-browser';
import { ANCHORS } from 'util/Config';
import AnchorBeforeSection from "components/AnchorBeforeSection";

mixpanel.init(
  `${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}`,
  {
    api_host: "https://api-eu.mixpanel.com",
  },
);


function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const noFooterRoutesRegex = RegExp('/reservation.*|/search.*');
  const logo_file_url = "https://d2c95mwu0p1ney.cloudfront.net/trymycar_svg_logo_vertically_centered.svg";

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
      mixpanel.track(
        "Navigated to link",
        {"url": url}
      );
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <MixpanelProvider mixpanel={mixpanel}>
    <FacebookPixel>
      <AuthProvider>
        <>
          <AnchorBeforeSection anchorText={`${ANCHORS.TOP_ANCHOR}`} />
          <NavbarCustom
            bg="white"
            variant="light"
            expand="lg"
            logo={logo_file_url}
          />
          <div style={{ marginTop: "57px" }}>
            <Component {...pageProps} />
          </div>        
          
          {noFooterRoutesRegex.test(router.pathname) ? null : (
            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              description="A short description of what you do here"
              copyright={"© " + new Date().getFullYear() + " TryMyCar Beta (eksperimentinė versija)"}
              logo={logo_file_url}
            />)}
        </>
      </AuthProvider>
    </FacebookPixel>
    </MixpanelProvider>
  );
}

export default MyApp;
