import firebase from "./firebase";

// Regular API request. Only text / json data, no files. 
export async function apiRequest(path, method = "GET", data, token = undefined) {
  // const currentUser = firebase.auth().currentUser;
  // const token = currentUser
  //   ? await firebase.auth().currentUser.getIdToken()
  //   : undefined;

  // if (data) data.ownerUid = currentUser.uid;
  // var auth = useAuth();
  // var token = auth.user ? auth.user.token : undefined;
  return fetch(`/api/0.1/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => {
      console.log("Next API response: ");
      console.log(response);
      return response.json();
    })
    .then((response) => {
      console.log("Next API JSON response: ");
      console.log(response);
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          firebase.auth().signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        // return response;
        return response.data;
      }
    });
}

// Regular API request. Only text / json data, no files. 
export async function apiRequestWithPayload(path, method = "GET", data) {
  const currentUser = firebase.auth().currentUser;
  const accessToken = currentUser
    ? await firebase.auth().currentUser.getIdToken()
    : undefined;

  if (data) data.ownerUid = currentUser.uid;
  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => {
      console.log("Next API response: ");
      console.log(response);
      return response.json();
    })
    .then((response) => {
      console.log("Next API JSON response: ");
      console.log(response);
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          firebase.auth().signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        // return response;
        return response.payload;
      }
    });
}


// Content-type: multipart / form-data. Useful when uploading files. 
export async function apiRequestWithFile(path, method = "POST", formdata) {
  const accessToken = firebase.auth().currentUser
    ? await firebase.auth().currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formdata,
  })
    .then((response) => {
      console.log("Next API-with-file response: ");
      console.log(response);
      return response.json();
    })
    .then((response) => {
      console.log("Next API-with-file JSON response: ");
      console.log(response);
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          firebase.auth().signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export function makeid(length) {
  var result           = [];
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  let id = result.join('');
  console.log(id);
  return id;
}