import React from 'react';
import { Language } from './Dictionary';

export default class AppState extends React.Component {
	static phoneNumber = null;
	static token = null;
	static vehicle = null;
	static booking = null;
	static bookingStart = null;
	static bookingEnd = null;
	static price = null;
	static discount = null;
	static discounts = null;
	static language = Language.LIT;
	static tutorialShown = false;
	static prodEnvironment = false;
	static currentScreen = 'Home';

	static resetBooking() {
		AppState.vehicle = null;
		AppState.booking = null;
		AppState.price = null;
		AppState.discount = null;
	}
}
