import React from "react";
import Section from "components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "components/SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAuth } from "util/auth.js";
import { useRouter } from "next/router";
import UserForm from 'components/UserForm';

function UserDetails(props) {
  const auth = useAuth();
  const router = useRouter();
  const { user, onSuccessfulUpdate } = props;

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        <Row className="align-items-center justify-content-center mt-5">
          <Col lg={6}>
            <UserForm onSuccessfulUpdate={onSuccessfulUpdate} phoneNumber={auth.user.phoneNumber} email={user.email} firstName={user.firstname} lastName={user.lastname} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default UserDetails;
