import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import queryString from "query-string";
import { useUser, createUser, updateUser } from "./db";
import router from "next/router";
import PageLoader from "./../components/PageLoader";
import { getFriendlyPlanId } from "./prices";
import analytics from "./analytics";
import Server from './Server';
import AppState from './AppState';
import { Dictionary } from './Dictionary';
import { storeUserLocally, retrieveUserFromLocalStore, removeUserFromLocalStore } from "./localStore";
import UserDetails from "components/UserDetails";

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = false;

// Whether to connect analytics session to user.uid
const ANALYTICS_IDENTIFY = true;

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [token, setToken] = useState(null);

  // // Format final user object and merge extra data from database
  // const finalUser = usePrepareUser(user);

  // // Connect analytics session to user
  // useIdentifyUser(finalUser);

  // Handle response from authentication functions
  const handleAuth = async (user) => {
    // Create the user in the database
    // fake-auth doesn't indicate if they are new so we attempt to create user every time
    await createUser(user.uid, { email: user.email });

    // Update user in state
    setUser(user);
    return user;
  };

  // function handleAuthCodeRequested(result) {
  //   if (result.status.value === true) {
  //     return
  //       // props.navigation.push('UserAuth', { phoneNumber: phone });
        
  //   } else {
  //       Alert.alert(Dictionary.ErrorAlertTitle[AppState.language], Dictionary.ErrorAlertMessage[AppState.language]);
  //       props.navigation.popToTop();
  //   }
  // }

  const checkForTokenError = (result) => {
    console.log("result: ")
    console.log(result)
    if (!result) return result;
    if (result.status.value === false) {
      // Automatically signout user if accessToken is no longer valid
      if (result.status.code === Server.error.AUTH_TOKEN_OR_PHONENR || result.status.code === Server.error.AUTH_TOKEN) {
        alert("Token (slaptažodis) neteisingas. Prašome jungtis iš naujo, arba susisiekti su TryMyCar pagalba: +37062513762");
        signout();
      }
      console.log(result.status.message);
    } else {
      return result;
    }
  }

  const handleLoginCompleted = async (phoneNumber, token) => {
      return Server().createQueryAsync(Server.USER_URL + '/' + phoneNumber + '/owner_profile_complete/', token)
      .then(result => {
        // check if the response contains an error message code about invalid token
        checkForTokenError(result);
        console.log("Profile completion test result: ")
        console.log(result);
        return result;
      }); 
  }

  const handleAuthCodeSubmitted = async (result, phoneNumber) => {
    if (result.status.value === true) {
        let token = result.payload.token;
        
        const profileCompleted = await handleLoginCompleted(phoneNumber, token);
        const { status } = profileCompleted;
        const { code, value } = status;

        let userLocal = {
          token: token,
          phoneNumber: phoneNumber,
          profileCompleted: value && code !== 515
        }
      
        setUser(userLocal);
        // Store user data locally or in a remote session with expiration
        storeUserLocally(userLocal);
        handleLoginCompleted(phoneNumber, token);
        return result.status.value;
    } else {
        alert(Dictionary.alertInvalidCode[AppState.language], Dictionary.alertVerifyCode[AppState.language]);
        return false;
    }
  }

  const signinWithPhone = (phone) => {
    const cleanPhoneNumber = phone.replace(/\s/g, "");
    return Server().createQueryAsync(Server.USER_URL + '/' + cleanPhoneNumber)
        .then(result => {
            // Request authentication code (create user or login)
            var url = (result.status.value === false) ? Server.REGISTER_URL : Server.LOGIN_URL;
            return Server().createQueryAsync(url + '/' + cleanPhoneNumber)
            .then(result => {return result.status.value});
        });
  };

  const signinWithOtpCode = async (otpCode, phoneNumber) => {
    console.log("otpCode: ")
    console.log(otpCode)
    console.log("phoneNumber: ")
    console.log(phoneNumber)
    return await Server().authCodeAsync(Server.LOGIN_URL, phoneNumber, otpCode)
    .then((result) => {
      return handleAuthCodeSubmitted(result, phoneNumber);
    });

    // return await handleAuthCodeSubmitted(result, phoneNumber);

    // return fakeAuth
    //   .signin(email, password)
    //   .then((response) => handleAuth(response.user));
  };

  const signinWithProvider = (name) => {
    // return fakeAuth
    //   .signinWithProvider(name)
    //   .then((response) => handleAuth(response.user));
  };

  const signout = () => {
    removeUserFromLocalStore();
    // TO DO:
    // - invalidate token on the backend 
    setUser(false);
  };

  const sendPasswordResetEmail = (email) => {
    // return fakeAuth.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (password, code) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").

    // Get code from query string object
    const resetCode = code || getFromQueryString("code");
    // return fakeAuth.confirmPasswordReset(password, resetCode);
  };

  const updateEmail = (email) => {
    // return fakeAuth.updateEmail(email).then((rawUser) => {
      // setUser(rawUser);
    // });
  };

  const updatePassword = (password) => {
    // return fakeAuth.updatePassword(password);
  };

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, name, picture } = data;

    // Update auth email
    if (email) {
      // await fakeAuth.updateEmail(email);
    }

    // Update auth profile fields
    if (name || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (picture) fields.picture = picture;
      // await fakeAuth.updateProfile(fields);
    }

    // Persist all data to the database
    await updateUser(user.uid, data);

    // Update user in state
    // const currentUser = await fakeAuth.getCurrentUser();
    setUser(currentUser);
  };

  const updateUserFromLocal = () => {
    const userLocal = retrieveUserFromLocalStore();
    if (userLocal) {
      setUser(userLocal);
    } else {
      setUser(false);
    }
  }

  useEffect(() => {
    updateUserFromLocal();
  }, []);

  return {
    user: user,
    handleLoginCompleted,
    signinWithPhone,
    signinWithOtpCode,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
    updateUserFromLocal,
  };
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const userDbQuery = useUser(MERGE_DB_USER && user && user.uid);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    let finalUser = {
      uid: user.uid,
      email: user.email,
      name: user.name,
      picture: user.picture,
    };

    // Include an array of user's auth providers, such as ["password", "google", etc]
    // Components can read this to prompt user to re-auth with the correct provider
    finalUser.providers = [user.provider];

    // If merging user data from database is enabled ...
    if (MERGE_DB_USER) {
      switch (userDbQuery.status) {
        case "idle":
          // Return null user until we have db data to merge
          return null;
        case "loading":
          return null;
        case "error":
          // Log query error to console
          console.error(userDbQuery.error);
          return null;
        case "success":
          // If user data doesn't exist we assume this means user just signed up and the createUser
          // function just hasn't completed. We return null to indicate a loading state.
          if (userDbQuery.data === null) return null;

          // Merge user data from database into finalUser object
          Object.assign(finalUser, userDbQuery.data);

          // Get values we need for setting up some custom fields below
          const { stripePriceId, stripeSubscriptionStatus } = userDbQuery.data;

          // Add planId field (such as "basic", "premium", etc) based on stripePriceId
          if (stripePriceId) {
            finalUser.planId = getFriendlyPlanId(stripePriceId);
          }

          // Add planIsActive field and set to true if subscription status is "active" or "trialing"
          finalUser.planIsActive = ["active", "trialing"].includes(
            stripeSubscriptionStatus
          );

        // no default
      }
    }

    return finalUser;
  }, [user, userDbQuery]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        router.replace("/auth/signup?next=" + window.location.pathname);
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoader />;
    }

    const { user } = auth;

    const handleUpdate = () => {
      storeUserLocally({ ...user, profileCompleted: true });
      router.push('/dashboard/my-cars');
      auth.updateUserFromLocal();
    }

    if (user && !user.profileCompleted) {
      return (
        <UserDetails
          bg="white"
          textColor="dark"
          size="md"
          title="Jūsų profilis"
          subtitle=""
          user={user}
          onSuccessfulUpdate={handleUpdate}
        />
      );
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

// Connect analytics session to current user.uid
function useIdentifyUser(user) {
  useEffect(() => {
    if (ANALYTICS_IDENTIFY && user) {
      analytics.identify(user.uid);
    }
  }, [user]);
}

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
