
// Default zoom level for maps
export const LATITUDE_DELTA = 0.23;
export const LONGITUDE_DELTA = 0.23;

// Map location setup
export const ANDROID_LOCATION_HIGH_ACCURACY = true;
export const ANDROID_LOCATION_TIMEOUT_MS = 15000;
export const ANDROID_LOCATION_MAX_AGE_MS = 5000;
export const IOS_LOCATION_HIGH_ACCURACY = true;
export const IOS_LOCATION_TIMEOUT_MS = 15000;
export const IOS_LOCATION_MAX_AGE_MS = 1000;

// Server poll intervals and timeouts
export const DB_POLL_INTERVAL_MS = 1000;
export const DB_POLL_TIMEOUT_MS = 30000;

// Distances for actions on vehicles
export const CAR_LOCK_DISTANCE_MAX = 25000000;       // Meters
export const CAR_SHOW_DISTANCE_MAX = 250;       // Meters

// Show registration screen when watching HomeScreen
export const LOGIN_POPUP_TIMEOUT_MS = 25000;

// Links to Drivest
export const DRIVEST_CONDITIONS = "https://trymycar.com/tc";
export const DRIVEST_PRIVACY_TERMS = "https://trymycar.com/privacy-policy";
export const DRIVEST_MESSENGER_LINK = "https://m.me/trymycar";

// Links to application stores
export const DRIVEST_APP_STORE_LINK = "#"; // https://apps.apple.com/us/app/trymycar/id1
export const DRIVEST_PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.trymycar";

// Hard-coded deposit in €
export const DEFAULT_DEPOSIT = 300;

export const ANCHORS = {
    CONTACTS_ANCHOR_LINK: "contacts",
    TOP_ANCHOR: "top"
}
