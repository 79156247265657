
export function storeUserLocally(user) {
    localStorage.setItem("TMC", JSON.stringify(user)); 
    return;
}

export function retrieveUserFromLocalStore() {
    let userData = window.localStorage.getItem("TMC");
    if (userData) {
      userData = JSON.parse(userData);
    }
    return userData;
}

export function removeUserFromLocalStore() {
    localStorage.removeItem("TMC"); 
    return;
}