import React from 'react';
import { format, parseISO } from 'date-fns';
import { useAuth } from "util/auth.js";

import AppState from './AppState';
import * as Config from './Config';
import { execOnDevelopmentEnvOrElse } from './Utils';

export const REQUEST_METHODS = {
	POST: 'POST',
	PUT: 'PUT',
	GET: 'GET',
	DELETE: 'DELETE'
}

export const availableCarsUrl = (start, end) => Server.AVAILABLE_CARS_URL
	.replace('{start}', format(start, "yyyy-MM-dd'T'H:mm:ss.SSSXX"))
	.replace('{end}', format(end, "yyyy-MM-dd'T'H:mm:ss.SSSXX"));

export default function Server() {
	
	const signoutUser = () => {
		// // TO DO: implement logout on the AuthProvider level 
		// auth.signout();
	};

	//--------------------------------------------------------------------------

	const updateBaseURL = () => {
		Server.BASE_URL_PREFIX = (AppState.prodEnvironment) ? "prod" : "dev";
		Server.BASE_URL = 'https://' + Server.BASE_URL_PREFIX + '.trymycar.com/api/' + Server.API_VERSION;
	}

	//--------------------------------------------------------------------------

	const ServerFetch = async (url, request) => {
		return fetch(Server.BASE_URL + url, request)
		.then((response) => {
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.indexOf("application/json") !== -1) {
			  return response.json();
			} else {
			  alert("Error occured. Try again. Or contact support@trymycar.com / +370 625 13762.")
			  throw "Error occured. Try again. Or contact support@trymycar.com / +370 625 13762.";
			}
		})
		.then((responseJSON) => {
			execOnDevelopmentEnvOrElse(
				() => console.log('Called URL', AppState.currentScreen, Server.BASE_URL + url, request, responseJSON)
			);
			return responseJSON
		})
		.then((response) => {

			return response;
			// TO DO:
			// -- implement auto- signout, if there is a Token error returned
			
			if (response.status.value === false) {
			  // Automatically signout user if accessToken is no longer valid
			  if (response.status.code === Server.error.AUTH_TOKEN_OR_PHONENR || response.status.code === Server.error.AUTH_TOKEN) {
				Server().signoutUser();
			  }
	  
			  throw new CustomError(response.status.code, response.status.message);
			} else {
			  return response;
			}
		})
		.catch((error) => { console.error(error) });
	}

	//--------------------------------------------------------------------------

	function createQueryAsync(url, token) {
		if (token == null) var request = { method: 'GET' };
		else var request = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

		return ServerFetch(url, request);
	}

	//--------------------------------------------------------------------------

	const authCodeAsync = (url, phoneNumber, authCode) => {
		console.log("phoneNumber: ")
		console.log(phoneNumber)
		let formdata = new FormData();
		formdata.append("phone_number", phoneNumber);
		formdata.append("auth_code", authCode);
		console.log("formdata: ")
		console.log(JSON.stringify(Object.fromEntries(formdata)))
		var request = {
			method: 'POST',
			// headers: { 'Content-Type': 'undefined' }, // multipart/form-data
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const updateUserParamAsync = (url, token, param, value) => {
		let formdata = new FormData();
		formdata.append(param, value);

		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const updateUserAsync = (url, token, { firstName, lastName, idCode, email, address, licenceNumber, licenceExpDate }) => {
		let formdata = new FormData();
		formdata.append(Server.USER_FIRSTNAME, firstName);
		formdata.append(Server.USER_LASTNAME, lastName);
		formdata.append(Server.USER_EMAIL, email);

		if (idCode) formdata.append(Server.USER_IDCODE, idCode);
		if (address) formdata.append(Server.USER_ADDRESS, address);
		if (licenceNumber) formdata.append(Server.USER_LICENSE, licenceNumber);
		if (licenceExpDate) formdata.append(Server.USER_LICENSEEXP, licenceExpDate);

		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const deleteUserAsync = (url, token) => {
		var request = {
			method: 'DELETE',
			headers: { 'Authorization': 'Bearer ' + token },
		};

		return ServerFetch(url, request);
	}

	const uploadPhotoAsync = (url, token, photoUri, method = REQUEST_METHODS.POST) => {
		let formdata = new FormData();
		formdata.append('file', {
			uri: Platform.OS === "android" ? photoUri : photoUri.replace("file://", ""),
			name: 'photo.jpg',
			type: 'image/jpg',
		});

		var request = {
			method,
			headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const createBookingAsync = (url, token, startTime, endTime, phoneNumber, carId) => {
		let formdata = new FormData();
		formdata.append("start_time", format(parseISO(startTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append("end_time", format(parseISO(endTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append("renter_phone_number", phoneNumber);
		formdata.append("car_id", carId);

		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const updateBookingAsync = ({ bookingId, token, startTime, endTime, phoneNumber, carId }) => {
		let formdata = new FormData();
		formdata.append("start_time", format(parseISO(startTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append("end_time", format(parseISO(endTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append("renter_phone_number", phoneNumber);
		formdata.append("car_id", carId);

		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(`${Server.BOOKING_URL}/${bookingId}`, request);
	}

	const createCarAsync = (url, data, token) => {
		let formdata = new FormData();
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				formdata.append(key, data[key]);
			}
		}
		console.log("formdata: ")
		console.log(JSON.stringify(Object.fromEntries(formdata)))
		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}
	
	const updateCarAsync = (url, data, token) => {
		let formdata = new FormData();
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				formdata.append(key, data[key]);
			}
		}
		console.log("formdata: ")
		console.log(JSON.stringify(Object.fromEntries(formdata)))
		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	} 

	const getCarsByOwner = (owner_id) => {
		return createQueryAsync(`/car/owner/${owner_id}`, null); 
	}

	const getCarById = (carId) => {
		return createQueryAsync(`/car/${carId}`, null); 
	}
	
	const getBookingsByOwner = (owner_id) => {
		return createQueryAsync(`/booking/car/owner/${owner_id}`, null); 
	}

	const setBookingStateAsync = (url, token) => {
		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
		};

		return ServerFetch(url, request);
	}

	const setCarPricingAsync = (url, pricingData, token) => {
		let formData = new FormData();
		formData.append("pricing", JSON.stringify(pricingData));
		// inspect form data
		for (var pair of formData.entries()) {
			console.log(pair[0]+ ', ' + pair[1]); 
		}
		var request = {
			method: 'PUT',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formData,
		};

		return ServerFetch(url, request);
	}

	const setCarPicturesAsync = (carId, formDataWithFiles, token) => {
		let url = Server.VEHICLE_URL + "/" + carId + Server.BOOKING_PICTURE_EXT;
		let formData = new FormData();
		formData.append("file", formDataWithFiles.carphoto[0]);
		// inspect form data
		for (var pair of formData.entries()) {
			console.log(pair[0]+ ', ' + pair[1]); 
		}
		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formData,
		};

		return ServerFetch(url, request);
	}

	const getPriceAsync = (url, token, startTime, endTime, carId) => {
		let formdata = new FormData();
		formdata.append("start_time", format(startTime, "yyyy-MM-dd'T'H:mm:ss.SSSXX"));
		formdata.append("end_time", format(endTime, "yyyy-MM-dd'T'H:mm:ss.SSSXX"));
		formdata.append("car_id", carId);

		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const logUserLocation = (url, token, phoneNumber, action, timestamp, latitude, longitude) => {
		let formdata = new FormData();
		formdata.append("phone_number", phoneNumber);
		formdata.append("action", action);
		formdata.append("timestamp", format(timestamp, "yyyy-MM-dd'T'H:mm:ss.SSSXX"));
		formdata.append("latitude", latitude);
		formdata.append("longitude", longitude);

		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const verifyAppVersion = (url, token, appVersion) => {
		let formdata = new FormData();
		formdata.append("version", appVersion);

		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(url, request);
	}

	const createUnavailable = (token, startTime, endTime, carId) => {
		console.log("Server() inputs:");
		console.log(token)
		console.log(startTime)
		console.log(endTime)
		console.log(carId)

		let formdata = new FormData();
		formdata.append('start_time', format(parseISO(startTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append('end_time', format(parseISO(endTime), "yyyy-MM-dd'T'HH:mm:ss.SSSXX"));
		formdata.append('car_id', carId);

		console.log("FormData: ");
		console.log(formdata.entries())

		var request = {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + token },
			body: formdata,
		};

		return ServerFetch(`/booking/set/unavailable`, request);
	}

	const getUserByPhoneNumber = (phoneNumber, token) => {
		return createQueryAsync(`/user/${phoneNumber}`, token); 
	}

	return {
		getCarsByOwner,
		getBookingsByOwner,
		setCarPricingAsync,
		setCarPicturesAsync,
		createCarAsync,
		signoutUser,
		updateBaseURL,
		createQueryAsync,
		authCodeAsync,
		updateUserParamAsync,
		updateUserAsync,
		deleteUserAsync,
		uploadPhotoAsync,
		createBookingAsync,
		setBookingStateAsync,
		getPriceAsync,
		logUserLocation,
		verifyAppVersion,
		createUnavailable,
		updateBookingAsync,
		getCarById,
		updateCarAsync,
		getUserByPhoneNumber
	};
}

// ----------------- STATIC VARIABLES --------------------

	Server.Platform = {
		OS: "android",
	}; 

	Server.API_VERSION = "v1";
	// Server.BASE_URL_PREFIX = execOnDevelopmentEnvOrElse(() => "dev", () => 'prod');
	Server.BASE_URL_PREFIX = process.env.NEXT_PUBLIC_BASE_URL_PREFIX;
	// Server.BASE_URL = 'https://' + Server.BASE_URL_PREFIX + '.trymycar.com/api/' + Server.API_VERSION;
	
	const host = {
		// development: 'http://127.0.0.1:5000',
		development: 'https://dev.trymycar.com',
		production: 'https://' + Server.BASE_URL_PREFIX + '.trymycar.com',
	} 
	Server.BASE_URL = host[process.env.NODE_ENV] + '/api/v1'; 

	Server.VEHICLE_URL = '/car';
	Server.AVAILABLE_CARS_URL = '/car/available/start/{start}/end/{end}';
	Server.REGISTER_URL = '/auth/register';
	Server.LOGIN_URL = '/auth/login';
	Server.USER_URL = '/user';
	Server.BOOKING_URL = '/booking';
	Server.DISCOUNTS_URL = '/discounts';
	Server.PRICE_URL = Server.BOOKING_URL + '/price';
	Server.LOG_LOCATION_URL = '/log/location';
	Server.VERSION_URL = '/version';

	Server.SELFIE_EXT = '/upload/selfie';
	Server.LICENSE_EXT = '/upload/drivers_license';
	Server.VEHICLE_LOCK_EXT = '/doors/lock';
	Server.VEHICLE_UNLOCK_EXT = '/doors/unlock';
	Server.VEHICLE_LOCATION_EXT = '/location';
	Server.BOOKING_ACTIVE_EXT = '/status/active';
	Server.BOOKING_COMPLETED_EXT = '/status/completed';
	Server.BOOKING_CANCELLED_EXT = '/status/cancelled';

	Server.BOOKING_PICTURE_EXT = '/picture';
	Server.BOOKING_PICTURE_AFTER_EXT = '/after';
	Server.BOOKING_PICTURE_BEFORE_EXT = '/before';

	Server.AUTH_CODE_LEN = 6;

	Server.USER_FIRSTNAME = 'firstname';
	Server.USER_LASTNAME = 'lastname';
	Server.USER_IDCODE = 'ID_code';
	Server.USER_EMAIL = 'email';
	Server.USER_ADDRESS = 'address';
	Server.USER_LICENSE = 'drivers_license';
	Server.USER_LICENSEEXP = 'drivers_license_exp';

	//--------------------------------------------------------------------------

	Server.error = {
		GENERAL_NO_ERROR: 0x0000,
		GENERAL_PROHIBITED_HTTP_METHOD: 0x0001,

		AUTH_MISC: 0x0100,
		AUTH_NOT_REGISTERED: 0x0101,
		AUTH_ALREADY_REGISTERED: 0x0102,
		AUTH_AUTH_CODE: 0x0103,
		AUTH_TOKEN_OR_PHONENR: 0x0104,
		AUTH_MISSING_TOKEN: 0x0105,
		AUTH_TOKEN: 0x0106,
		AUTH_ADMIN_TOKEN_OR_PHONENR: 0x0107,
		AUTH_ADMIN_TOKEN: 0x0108,
		AUTH_ADMIN_NOT_AUTHORIZED: 0x0109,

		USER_MISC: 0x0200,
		USER_NOT_REGISTERED: 0x0201,
		USER_NOT_AUTHORIZED: 0x0202,
		USER_PROFILE_INCOMPLETE: 0x0203,
	};

	//--------------------------------------------------------------------------

	Server.transmission = {
		MAN: 0x00,
		AUT: 0x01,
	};

	Server.fuel = {
		PETROL_95: 0x00,
		PETROL_98: 0x01,
		DIESEL: 0x02,
		ELECTRIC: 0x03,
		HYBRID: 0x04, 
		LNG: 0x05, 
	};

	Server.bookingStatus = {
		CREATED: 0,
		PAID: 11,
		CONFIRMED_BY_OWNER: 22,
		ACTIVE: 33,
		COMPLETED: 44,
		CANCELLED: 88,
	};

	Server.discount = {
		GLOBAL: 0x00,
		CODE: 0x01,
	};

	Server.carType = {
		HATCHBACK: 0x00,
		ESTATE: 0x01,
		SALOON: 0x02,
		SUV: 0x03,
		VAN: 0x04,
	};

	Server.carStatus = {
		INACTIVE: 0,
		PENDING: 10,
		ACTIVE: 20,
		SUSPENDED: 30
	}