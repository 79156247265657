import React from 'react';

import AppState from './AppState';

export default class Utils extends React.Component {

	static isUserLoggedIn() {
		return (AppState.phoneNumber != null && AppState.token != null);
	}

	static stripWhitespace(string) {
		return string.replace(/\s/g, '');
	}

}

export function isDev() {
	return process.env.NODE_ENV !== 'production';
}

export function execOnDevelopmentEnvOrElse(fn, orElse = () => { }) {
	return isDev() ? fn() : orElse();
}