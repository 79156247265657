import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FormField from "components/forms/FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "util/auth.js";
import { useForm } from "react-hook-form";
import Server from "util/Server";

function UserForm(props) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);
  const { handleSubmit, register } = useForm();
  const { email, firstName, lastName, onSuccessfulUpdate } = props;

  // Handle form submission
  const onSubmit = async (data) => {
    // Show pending indicator
    setPending(true);

    try {
      const { status, payload } = await Server().updateUserAsync(`${Server.USER_URL}/${auth.user.phoneNumber}`, auth.user.token, data);
      if (status.value === true) {
        onSuccessfulUpdate();
      }
    } catch (e) {
      console.log('error updating user', e);
    } finally {
      setPending(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="firstName">
        <FormField
          size="lg"
          name="firstName"
          placeholder="Vardenis"
          inputRef={register({
            required: "Prašome įvesti savo vardą",
          })}
          defaultValue={firstName}
        />
      </Form.Group>
      <Form.Group controlId="lastName">
        <FormField
          size="lg"
          name="lastName"
          type="text"
          placeholder="Pavardenis"
          inputRef={register({
            required: "Prašome įvesti savo pavardę",
          })}
          defaultValue={lastName}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <FormField
          size="lg"
          name="email"
          type="email"
          placeholder="vardenis@pavardenis.lt"
          inputRef={register({
            required: "Prašome įvesti savo el. paštą",
          })}
          defaultValue={email}
        />
      </Form.Group>
      <Button
        variant="primary"
        block={true}
        size="lg"
        type="submit"
        disabled={pending}
      >
        {!pending && <span>Išsaugoti</span>}

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="align-baseline"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  );
}

export default UserForm;
