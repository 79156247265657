import Analytics from "analytics";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import segmentPlugin from '@analytics/segment';
import Router from "next/router";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    // googleAnalyticsPlugin({
    //   trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    // }),
    segmentPlugin({
      writeKey: 'tfMkVp6FJ6aT3IANP4DJAyqMjEHz89av'
    }),
  ],
});

// Track initial pageview
if (typeof window !== "undefined") {
  analytics.page();
}

// Track pageview on route change
Router.events.on("routeChangeComplete", (url) => {
  analytics.page();
});

export default analytics;
