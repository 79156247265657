import React from "react";

function AnchorBeforeSection(props) {

  return (
      <div style={{ marginBottom: "0px" }}>
        <a name={props.anchorText}></a>
      </div>
  );
}

export default AnchorBeforeSection;
